<template>
  <CFormLabel for="input-password" v-if="showLabel">{{ label }}</CFormLabel>
  <CInputGroup class="mb-3 formelements-password">
    <CInputGroupText><CIcon icon="cil-lock-unlocked"/></CInputGroupText>
    <CFormInput
      id="input-password"
      :type="inputType"
      autocomplete="new-password"
      :invalid="vld.$invalid"
      :valid="vld.$dirty"
      v-model="local"
      @blur="vld.$commit"
    />
    <CButton
      type="button"
      color="secondary"
      variant="outline"
      id="password-hidden"
      v-if="modus == 'hidden' && createMode"
      @click="switchInputType"
    >
      <CIcon icon="cil-magnifying-glass"/>
    </CButton>
    <CButton
      type="button"
      color="secondary"
      variant="outline"
      id="password-shown"
      v-if="modus == 'visible' && createMode"
      @click="switchInputType"
    >
      <CIcon icon="cil-low-vision"/>
    </CButton>
    <CButton
      type="button"
      color="secondary"
      variant="outline"
      id="password-generate"
      v-if="createMode"
      @click="generatePassword"
    >
      <CIcon icon="cil-casino"/>
    </CButton>
    <CFormFeedback invalid v-if="vld.$invalid">
      Bitte geben Sie ein Passwort ein.
    </CFormFeedback>
  </CInputGroup>
  <password-meter secureLength="8" :password="local" v-if="createMode"/>
</template>

<script>
import passwordMeter from 'vue-simple-password-meter'
import passwordGenerator from 'generate-password'

export default {
  name: 'formelements-password',
  components: {
    passwordMeter
  },
  props: {
    label: {
      default: 'Passwort',
      type: String
    },
    showLabel: {
      default: true,
      type: Boolean
    },
    createMode: {
      default: true,
      type: Boolean
    },
    vld: {
      type: Object,
      required: true
    },
    modelValue: String
  },
  computed: {
    local: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    },
    inputType () {
      if (this.modus === 'hidden') {
        return 'password'
      } else {
        return 'text'
      }
    }
  },
  data () {
    return {
      modus: 'hidden'
    }
  },
  methods: {
    switchInputType () {
      if (this.modus === 'hidden') {
        this.modus = 'visible'
      } else {
        this.modus = 'hidden'
      }
    },
    generatePassword () {
      this.local = passwordGenerator.generate({ length: 12, numbers: true })
    }
  }
}
</script>
